.App {
  text-align: left;
  color: #172808;
  background:#394032;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  
 /* min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  font-size: calc(10px + 2vmin);
  color: #CFEE9E;
   text-align: right;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a,
button,
input,
select,
h1,
h2,
h3,
h4,
h5,
* {
  margin: 0;
  padding: 0;
  border: none;
  text-decoration: none;
  appearance: none;


  -webkit-font-smoothing: antialiased;
}

.placeholder{
  text-align: center;
  font-size: x-large;
}

.name {
  margin: 0;
  padding-left: 0%;
  padding-bottom: 5%;
}

.name_header {
  font-size: larger;
  font-weight: bold;
  margin: 0;
  padding-left: 0%;
  padding-bottom: 0%;
}


.details_subhead{
  display: flex;
  padding-left: 2%;
  padding-bottom: 5%;

}

.details_content{
  display: flex;
  flex: 1;
  padding-left: 10%;
  padding-bottom: 5%;  
}

.flex-grid{
  display: flex;
  margin: 0;
  padding: 0;
}

.col{
  flex: 1;
}

.center {
  border: 5px solid;
  margin: auto;
  width: 50%;
  padding: 10px;
}

.right {
  float: right;
  width: 300px;
  border: 3px solid #73AD21;
  padding: 10px;
}
.text {
  margin: auto;
}

.box{
  height: 100px;
  width: 100px;
  background-color: red;
}

.namebox{
  height: auto;
  display: inline-block;
  width: fit-content;
  border-radius: 5px;
  background-color: #8DAB7F;
  padding: 3px;
  padding-right: 10px;
}

.detailbox{
  height: auto;
  width: auto;
  border-radius: 5px;
  background-color: #8DAB7F;
  padding: 3px;
  padding-right: 10px;
}

